import React from 'react'
import Layout from '@/components/Layout'
import Contact from '@/components/pages/Contact'

export default () => {
  return (
    <Layout
      title="Nilly's Burgers - Contact"
      metaDescription="We love connecting with people! Follow us on all our social medias, contact us through phone or email, or visit us at our shop in Artesia, CA!"
    >
      <Contact />
    </Layout>
  )
}
