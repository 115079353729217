import React, { memo, useState } from 'react'
import {
  Box,
  Flex,
  Text,
  Icon,
  Link,
  AspectRatio,
  useColorModeValue,
  Skeleton,
  BoxProps as ChakraBoxProps,
  TextProps as ChakraTextProps,
} from '@chakra-ui/react'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { nanoid } from 'nanoid'
import { BUSINESS_INFO } from '@/data/business-info'
import { BUSINESS_HOURS } from '@/data/business-hours'
import {
  ADDRESS_MAP_LINK,
  ADDRESS,
  ADDRESS_EMBED_SRC,
} from '@/data/business-address'
import { BusinessHours } from '@/components'

const BUSINESS_INFO_SECTIONS = {
  contact: {
    phone: BUSINESS_INFO.phone,
    email: BUSINESS_INFO.email,
  },
  follow: {
    instagram: BUSINESS_INFO.instagram,
    twitter: BUSINESS_INFO.twitter,
  },
}

const headingStyles: ChakraTextProps = {
  textStyle: 'h3',
  mb: 2,
  textAlign: ['center', 'center', 'left'],
  textTransform: 'uppercase',
}

const Contact: React.FC = () => {
  const [isGoogleMapLoaded, setIsGoogleMapLoaded] = useState(false)

  const DayOfWeekAbbr = Object.keys(BUSINESS_HOURS)

  const textHoverColor = useColorModeValue(
    'light.footer.textHover',
    'dark.footer.textHover'
  )
  const textHoverStyles = {
    _hover: {
      color: textHoverColor,
    },
    _focus: {
      color: textHoverColor,
    },
    _active: {
      color: textHoverColor,
    },
  }

  return (
    <Flex id="contact" justifyContent="center" p={10} m={10}>
      <Flex
        flexWrap="wrap"
        flexDirection={['column', 'row']}
        maxWidth={['100%', '30rem', '50rem']}
        boxSizing="content-box"
        columnGap={10}
        rowGap={20}
        justifyContent="center"
        justifyItems="flex-start"
        alignItems={['center', 'center', 'flex-start']}
      >
        {/* CONTACT US +  FOLLOW US */}
        {Object.keys(BUSINESS_INFO_SECTIONS).map((key: string) => {
          const title = `${key} US`
          const info = BUSINESS_INFO_SECTIONS[key]
          return (
            <Section title={title} key={nanoid()}>
              <Box ml={key === 'contact' ? [6, 6, 0] : undefined}>
                {Object.keys(info).map((k) => {
                  const { value, href, icon } = info[k]
                  return (
                    <Link href={href} key={nanoid()}>
                      <Flex
                        alignItems="center"
                        {...textHoverStyles}
                        mb={[0, 1]}
                      >
                        <Icon as={icon} fontSize="1em" />
                        <Text ml={key === 'contact' ? 3 : 2.5} fontSize="1em">
                          {value}
                        </Text>
                      </Flex>
                    </Link>
                  )
                })}
              </Box>
            </Section>
          )
        })}

        {/* BUSINESS HOURS  */}
        <Section title="BUSINESS HOURS">
          <Flex display="flex">
            <BusinessHours gridGap={[1, 1.5, 2, 2.5]} />
          </Flex>
        </Section>

        {/* VISIT US */}
        <Section title="VISIT US">
          <Flex
            width="fit-content"
            justifyContent={['center', 'center', 'flex-start']}
            alignItems={['center', 'center', 'flex-start']}
            flexDirection="column"
          >
            <Link href={ADDRESS_MAP_LINK} target="_blank">
              <Flex alignItems="center" mb={1} {...textHoverStyles}>
                <Icon as={FaMapMarkerAlt} fontSize="1em" />
                <Text ml={1} fontSize="1em">
                  {ADDRESS}
                </Text>
              </Flex>
            </Link>
            <AspectRatio
              width={['100%', 320, 340]}
              height={[250, 210]}
              ratio={1}
              mt={[4, 4, 2]}
            >
              <>
                {!isGoogleMapLoaded && (
                  <Skeleton height="inherit" width="100%" />
                )}
                <iframe
                  src={ADDRESS_EMBED_SRC}
                  width="inherit"
                  height="inherit"
                  frameBorder="0"
                  style={{ border: 0 }}
                  onLoad={() => setIsGoogleMapLoaded(true)}
                  allowFullScreen
                />
              </>
            </AspectRatio>
          </Flex>
        </Section>
      </Flex>
    </Flex>
  )
}

interface ISection extends ChakraBoxProps {
  title: string
  children: React.ReactNode
}
const Section: React.FC<ISection> = ({ title, children, ...props }) => (
  <Box
    as="section"
    width={['fit-content', 'fit-content', '45%']}
    boxSizing="border-box"
    fontSize={['16px', '17px', '18px', '18px']}
    {...props}
  >
    <Box>
      <Text {...headingStyles}> {title} </Text>
      <Box>{children}</Box>
    </Box>
  </Box>
)

export default memo(Contact)
